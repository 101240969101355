<template>
  <transition name="fade">
    <div class="message" v-if="visible"> 
      <div class="content">{{content}} 
      </div>
    </div>
  </transition>
</template> 
<script>
  export default {
    name: "MyMessage",
    data() {
      return {
        content: '',
        time: 2000,
        visible: false,  
      }
    },
    mounted() {
      this.close()
    },
    methods: {
      close() {
        window.setTimeout(() =>{
          this.visible = false
        }, this.time);
      }
    }
  }
</script> 
<style scoped lang="scss">
  .message{
    width:1.5rem; 
    text-align: center;
    background: rgba(0,0,0,.5);
    color:#fff;
    font-size: 14px;
    padding:10px 8px;
    border-radius: 3px;
    position:absolute;
    top:45%;
    left:1.125rem; 
    z-index: 99; 
  }
</style>