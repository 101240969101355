// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue/dist/vue.esm.js'
import App from './App'
import router from './router'
import axios from 'axios'
import store from "./store";
import Message from '@/components/common/message'
import moment from 'moment/moment'
import { getStorage} from "@/js/common";  

// import { Picker } from 'vant';

// Vue.use(Picker);
Vue.prototype.$Message = Message.install;
Vue.prototype.$moment = moment;
Vue.config.productionTip = false; 
Vue.prototype.$axios = axios;
Vue.prototype.$store = store; 
//验证是否登录，路由拦截 
// router.beforeEach(async (to, from, next) => {
//   let token = getStorage('localdata') && getStorage('localdata').token; 
//   let toPath = to.path;
//   // let fakeUrl =   window.location.origin+'/#'+toPath;
//   // if(toPath !== '/'){ 
//   //   history.pushState(null, null,fakeUrl);
//   // } 
//   if ((toPath === '/' && !token) || (toPath === '/resetPassWord' && !token) || (toPath === '/register' && !token)) {
//     next();
//   }
//   else if (!token) {
//     console.log('未登录')
//     next("/")
//   }
//   next();
// }) 
/* eslint-disable no-new */
const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
export default vm


