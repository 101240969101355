

// 存储localstorage数据
export const setStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
}
// 取出localstorage数据
export const getStorage = (key) => { 
  if(!localStorage.getItem(key) ||localStorage.getItem(key) == 'undefined'){
    return ""
  }else{
    return JSON.parse(localStorage.getItem(key));
  }
}
// 删除localstorage数据
export const removeStorage = (key) => {
  localStorage.removeItem(key);
}


export const getQueryVariable = (variable)=>
{
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
}

export const authentication = {    //开建申请
  credentials:{
      client_id:'1etAvb3tgMFk30uf0abv9Rt',   
      client_secret:'1uHX5q3tgMFk2y1L6g40gY27', 
      grant_type:'client_credentials'
  },
  baseUrl:'https://api.cloud.pkpm.cn',
}  
