import Vue from 'vue/dist/vue.esm.js'
import Vuex from 'vuex';
import defaultState from './state/state'
import mutations from './mutations/mutations'
import actions from './actions/actions' 
Vue.use(Vuex); 
export default () => {
  return new Vuex.Store({
    state: defaultState,
    mutations,
    actions
  }) 
}