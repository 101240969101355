import Vue from 'vue/dist/vue.esm.js'
import Router from 'vue-router' 
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}   
Vue.use(Router) 
export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component:() => import('@/components/index/index')
    },
    {
      path: '/SelectUnits',
      name: 'SelectUnits',
      component:() => import('@/components/SelectUnits/index')
    },
    {
      path: '/UnitsDetail',
      name: 'UnitsDetail',
      component:() => import('@/components/UnitsDetail/index')
    },
    {
      path: '/ProductionSettings',
      name: 'ProductionSettings',
      component:() => import('@/components/ProductionSettings/index')
    },
    {
      path: '/ProductionDetails',
      name: 'ProductionDetails',
      component:() => import('@/components/ProductionDetails/index')
    },
    {
      path: '/FakeDigitalSite',
      name: 'FakeDigitalSite',
      component:() => import('@/components/FakeDigitalSite/index')
    }
    
  ]
})
